// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("@rails/activestorage") #this ruined something critical w/ jquery and datatables
require('bootstrap-sass');
require('bootstrap-datepicker');

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

//import PerfectScrollbar from './perfect-scrollbar.min';
import PerfectScrollbar from "perfect-scrollbar"
import './scrollbar-custom.umd'

require('datatables.net-bs4');

import toastr from 'toastr'
window.toastr = toastr
import "./best_in_place"
//import "./coreui.min" //- couldnt get modal to move from 'in' to 'show', importing now in app layout
import "./fontawesomekit"
import "./creoki_custom"

//import './popper.min'
//import './perfect-scrollbar.min'
//import './coreui.min'
//require("turbolinks").start();
//require("channels");
//import './bootstrap_custom.js'
//require( 'jszip' )
//require( 'pdfmake' )
//require( 'datatables.net-bs')
//require( 'datatables.net-buttons-bs4' )
//require( 'datatables.net-buttons/js/buttons.colVis.js' )
//require( 'datatables.net-buttons/js/buttons.html5.js' )
//require( 'datatables.net-fixedheader-bs4' )
//require( 'datatables.net-scroller-bs4' )
//import './datatables.js'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
